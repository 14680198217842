import React, { useState, useEffect, useCallback } from 'react';
import '../styles/cards.scss';
import AddHome from './AddHome';
import EditHome from './EditHome';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_URL } from './Constants';

const MyListings = () => {
  const [homes, setHomes] = useState([]);
  const [editHomeId, setEditHomeId] = useState(null); // Track which home is being edited
  const navigate = useNavigate();
  const [editErrors, setEditErrors] = useState([]);
  const [addErrors, setAddErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchHomes = useCallback(() => {
    const token = localStorage.authToken;
    fetch(`${API_URL}/my-listings`, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // Redirect the user to the login page if unauthorized
            navigate('/login');
            return; // Exit the function early
          } else {
            throw new Error(response.error || 'An unknown error occurred.');
          }
        }
        return response.json();
      })
      .then((data) => {
        setHomes(data);
      })
      .catch(() => {
        navigate('/login');
      });
  }, [navigate]);

  useEffect(() => {
    fetchHomes();
  }, [fetchHomes]);

  const handleEditToggle = useCallback((id) => {
    setEditHomeId(editHomeId === id ? null : id);
  }, [editHomeId]);

  const handleAddHome = useCallback(async ({ address, bac, link, saleType, file, clearFormInputs }) => {
    const token = localStorage.authToken;

    const newErrors = [];
    if (!token) {
      newErrors.push('You must be logged in to perform this action.');
    }
    if (address.length < 8) {
      newErrors.push('Address must be at least 8 characters.');
    }
    if (newErrors.length > 0) {
      setAddErrors(newErrors);
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('home[address]', address);
    formData.append('home[bac]', bac);
    formData.append('home[link]', link);
    formData.append('home[sale_type]', saleType);

    // Append the file if it exists
    if (file) {
      formData.append('home[file]', file); // 'file' matches what we use in the controller
    }

    try {
      const response = await fetch(`${API_URL}/homes`, {
        method: 'POST',
        headers: {
          Authorization: `${token}`, // Don't set Content-Type explicitly
        },
        body: formData, // Use formData to send both file and text fields
      });

      if (!response.ok) {
        const data = await response.json();
        if (response.status === 409) {
          setShowModal(true);
        } else if (response.status === 401) {
          navigate('/login');
        } else {
          throw new Error(data.error || 'An unknown error occurred.');
        }
      } else {
        const newHome = await response.json();
        setHomes((prevHomes) => [...prevHomes, newHome]);
        clearFormInputs();
        setAddErrors([]);
      }
    } catch (error) {
      setAddErrors([error.message || 'An unknown error occurred. Please try again.']);
    }
  }, [navigate]);


  const handleDuplicateConfirm = useCallback(async ({ address, bac, link, saleType, file, clearFormInputs }) => {
    setShowModal(false);
    setAddErrors([]);

    const token = localStorage.authToken;

    try {
      // Prepare form data
      const formData = new FormData();
      formData.append('home[address]', address);
      formData.append('home[bac]', bac);
      formData.append('home[link]', link);
      formData.append('home[sale_type]', saleType);
      
      // Add force_duplicate as a top-level field, not inside 'home'
      formData.append('force_duplicate', true); 
      
      // Append the file if it exists
      if (file) {
        formData.append('home[file]', file); // 'file' matches what we use in the controller
      }
      
      const response = await fetch(`${API_URL}/homes`, {
        method: 'POST',
        headers: {
          Authorization: `${token}`, // Don't set Content-Type explicitly when using FormData
        },
        body: formData, // Use formData instead of JSON.stringify
      });      

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login');
        }
        const data = await response.json();
        throw new Error(data.error || 'An unknown error occurred.');
      }

      const newHome = await response.json();
      setHomes((prevHomes) => [...prevHomes, newHome]);
      clearFormInputs(); // Call clearFormInputs here to clear the form after success
      setAddErrors([]);
    } catch (error) {
      setAddErrors([error.message || 'An unknown error occurred. Please try again.']);
    }
  }, [setHomes, setAddErrors, setShowModal, navigate]);

  const handleDuplicateCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  // Delete a home
  const handleDelete = useCallback(async (homeId) => {
    const token = localStorage.authToken;
    try {
      const response = await fetch(`${API_URL}/homes/${homeId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Redirect the user to the login page if unauthorized
          navigate('/login');
          return; // Exit the function early
        }

        const data = await response.json();
        throw new Error(data.error || 'An error occurred while trying to delete the listing.');
      }

      fetchHomes(); // Refresh the homes list after deletion
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while trying to delete the listing.');
    }
  }, [fetchHomes]);

  //Edit a home
  const handleEdit = useCallback(async (e, home, address, bac, link, saleType, file) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const token = localStorage.authToken;
    const newErrors = [];

    if (!token) {
      newErrors.push('You must be logged in to perform this action.');
    }

    if (address.length < 8) {
      newErrors.push('Address must be at least 8 characters.');
    }

    if (newErrors.length > 0) {
      setEditErrors(newErrors); // Set errors in state
      return;
    }

    // Create FormData to handle file upload along with other form fields
    const formData = new FormData();
    formData.append('home[address]', address);
    formData.append('home[bac]', bac);
    formData.append('home[link]', link);
    formData.append('home[sale_type]', saleType);

    // Only append the file if it exists
      if (file) {
      formData.append('home[file]', file);
    }

    try {
      const response = await fetch(`${API_URL}/homes/${home.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `${token}`,
          // Remove 'Content-Type' because fetch will automatically set the correct headers
          // for FormData (including `multipart/form-data`)
        },
        mode: 'cors',
        body: formData, // Send FormData containing the file and other fields
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Redirect the user to the login page if unauthorized
          navigate('/login');
          return; // Exit the function early
        }

        const data = await response.json();
        throw new Error(data.error || 'An unknown error occurred.');
      }

      const updatedHome = await response.json();

      // Update the edited home in the state
      setHomes((prevHomes) =>
        prevHomes.map((h) => (h.id === home.id ? updatedHome : h))
      );

      // Clear edit form state and errors
      setEditHomeId(null);
      setEditErrors([]);
      return updatedHome;
    } catch (error) {
      setEditErrors([error.message || 'An unknown error occurred. Please try again.']);
    }
  }, [setHomes, setEditErrors]);
  

  const handleLinkClick = (url, e) => {
    e.preventDefault();
    if (url) {
      const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
      window.open(formattedUrl, '_blank');
    }
  };

  return (
    <div>
      <AddHome
        handleAddHome={handleAddHome}
        onDuplicateConfirm={handleDuplicateConfirm}
        onDuplicateCancel={handleDuplicateCancel}
        errors={addErrors}
        showModal={showModal}
      />
      <div className="py-md">My Listings</div>
      {homes.length > 0 ? (
        homes.map((home) => (
          <React.Fragment key={home.id}>
            <div className="address-card mb-2">
              <div className="card-address">{home.address}</div>
              <div className="card-bac pr-sm">
                <span className="marg-r-xsm italic">({home.sale_type || 'Sale'})</span> {home.bac}
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-end">
                <Button
                  className={`btn-primary2 mb-2 marg-l-sm mb-sm-0 ${!home.link ? 'disabled-button' : ''}`}
                  onClick={(e) => handleLinkClick(home.link, e)}
                  disabled={!home.link} // Disable the button if there is no link
                >
                  Link
                </Button>
                <Button
                  className="edit-button marg-l-sm btn-primary2"
                  onClick={() => handleEditToggle(home.id)}
                >
                  Edit
                </Button>
              </div>
            </div>
            {editHomeId === home.id && (
              <div className="mb-2">
                <EditHome home={home} fetchHomes={fetchHomes} onDelete={handleDelete} onEdit={handleEdit} externalErrors={editErrors}  /> {/* Pass handleDelete as a prop */}
              </div>
            )}
          </React.Fragment>
        ))
      ) : (
        <div>No homes found</div>
      )}
    </div>
  );
};

export default MyListings;