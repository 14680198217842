import React, { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from './components/Constants';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null); // Store user information

  useEffect(() => {
    // Check if a token and user data exist in localStorage and validate it
    const storedToken = localStorage.getItem('authToken');
    const storedUser = localStorage.getItem('user');
    if (storedToken && storedUser) {
      setToken(storedToken);
      setUser(JSON.parse(storedUser)); // Parse and set user data
      setIsLoggedIn(true);
    }
  }, []);

  const login = async (license_number, password) => {
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ license_number, password }),
      });
      const data = await response.json();
      if (data.token) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('user', JSON.stringify(data.user)); // Store user information
        setToken(data.token);
        setUser(data.user); // Set user state
        setIsLoggedIn(true);
        return true; // Return success
      }
    } catch (error) {
      return false; // Return failure
    }
  };  

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    setToken(null);
    setUser(null);
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);