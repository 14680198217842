import React, { useState, useRef, useCallback } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import '../styles/login.scss';

const AddHome = ({ handleAddHome, onDuplicateConfirm, onDuplicateCancel, errors, showModal }) => {
  const [address, setAddress] = useState('');
  const [bac, setBac] = useState('');
  const [link, setLink] = useState('');
  const [file, setFile] = useState(null); // State for the uploaded file
  const [saleType, setSaleType] = useState('Sale'); // Default to 'Sale'
  const autocompleteRef = useRef(null);
  const fileInputRef = useRef(null); // Add ref for the file input

  const handleLoad = useCallback((autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const handlePlaceChanged = useCallback(() => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      setAddress(place.formatted_address);
    }
  }, []);

  const handleSaleTypeChange = useCallback((e) => {
    setSaleType(e.target.value);
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Store the selected file
  };

  const clearFormInputs = useCallback(() => {
    setAddress('');
    setBac('');
    setLink('');
    setFile(null); // Clear the file state
    setSaleType('Sale');
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input field
    }
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    handleAddHome({ address, bac, link, saleType, file, clearFormInputs }); // Include the file in the submission
  }, [handleAddHome, address, bac, link, saleType, file, clearFormInputs]);

  const handleDuplicateConfirm = useCallback((e) => {
    e.preventDefault();
    onDuplicateConfirm({ address, bac, link, saleType, file, clearFormInputs }); // Include the file for duplicates
  }, [onDuplicateConfirm, address, bac, link, saleType, file, clearFormInputs]);

  return (
    <div className="add-home-form marg-t-md">
      <Card style={{ width: '100%' }}>
        <Card.Header className="text-center">Add a Listing</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            {errors && errors.length > 0 && (
              <div className="error-message">
                {errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            )}
            <Form.Group controlId="formAddress">
              <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Search for an address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={{ padding: '0.5rem' }}
                  />
                </div>
              </Autocomplete>
            </Form.Group>
            <Form.Group controlId="formBac">
              <Form.Control
                type="text"
                value={bac}
                onChange={(e) => setBac(e.target.value)}
                placeholder="Buyer agent compensation offered"
              />
            </Form.Group>
            <Form.Group controlId="formLink">
              <Form.Control
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Enter optional link (e.g., compensation document)"
              />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Optional Document Upload</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange} // Handle file upload
                accept=".pdf,.doc,.docx" // Accept only PDFs, Word documents, etc.
                ref={fileInputRef} // Attach the ref to the file input
              />
              {file && <p>Selected File: {file.name}</p>}
            </Form.Group>
            <Form.Group controlId="formSaleType">
              <div>
                <Form.Check
                  type="radio"
                  label="Sale"
                  name="saleType"
                  value="Sale"
                  checked={saleType === 'Sale'}
                  onChange={handleSaleTypeChange}
                  inline
                />
                <Form.Check
                  type="radio"
                  label="Lease"
                  name="saleType"
                  value="Lease"
                  checked={saleType === 'Lease'}
                  onChange={handleSaleTypeChange}
                  inline
                />
              </div>
            </Form.Group>
            <Button variant="primary" className="btn-primary2" type="submit">
              Add listing
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={onDuplicateCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have already created a listing for this address. Are you sure you want to create another?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancel-button" onClick={onDuplicateCancel}>
            Cancel
          </Button>
          <Button variant="primary" className="btn-primary2" onClick={handleDuplicateConfirm}>
            Create duplicate
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddHome;