const prod = {
  API_URL: "https://bac-backend-3d75a7bdd659.herokuapp.com"
};

const dev = {
  API_URL: "http://localhost:3001"
};

export const API_URL = process.env.REACT_APP_ENV === "development" ? dev.API_URL : prod.API_URL;
export const libraries = ['places'];
export const googleMapsApiKey = process.env.REACT_APP_SEARCH_API_KEY;