import React, { useState, useRef, useCallback } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/cards.scss';
import { API_URL } from './Constants';
import { FaLinkSlash } from "react-icons/fa6";
import { ImNewTab } from "react-icons/im";

// Utility function to format date
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const SearchHomes = () => {
  const [homes, setHomes] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(''); // Add state for the document
  const autocompleteRef = useRef(null);

  const handleLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.formatted_address) {
        setInputValue(place.formatted_address);
        setError('');
        fetchHomes(place.formatted_address);  // Fetch homes only when a full address is selected
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.length >= 6) {
      setError('');
    }
  };

  const fetchHomes = useCallback((address) => {
    fetch(`${API_URL}/homes?address=${encodeURIComponent(address)}`)
      .then(response => response.json())
      .then(data => {
        const homesData = data || [];
        setHomes(homesData);
        setHasSearched(true);
      })
      .catch(() => {
        setError('An error occurred while fetching data.');
      });
  }, []);

  const handleInputKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission or other default actions
      if (inputValue.length >= 6) {
        fetchHomes(inputValue); // Call fetchHomes only on Enter
      } else {
        setError('Please enter at least 6 characters.');
      }
    }
  }, [inputValue, fetchHomes]);

  const handleCardClick = (home) => {
    if (home.link || home.file_url) { // Check for both link and file_url
      setSelectedLink(home.link || ''); // Store the link if it exists
      setSelectedDocument(home.file_url ? `${API_URL}${home.file_url}` : ''); // Store the document URL if it exists, prepend API_URL
      setShowModal(true);
    }
  };

  const handleConfirmNavigation = () => {
    const formattedUrl = selectedLink.startsWith('http://') || selectedLink.startsWith('https://') ? selectedLink : `http://${selectedLink}`;
    window.open(formattedUrl, '_blank');
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDocument(''); // Clear document state when modal closes
  };

  return (
    <div className="marg-t-md">
      <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
        <input
          type="text"
          placeholder="Search homes by address"
          value={inputValue}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyPress}
          style={{ width: '100%', padding: '0.5rem', marginBottom: '1em', marginTop: '1em' }}
        />
      </Autocomplete>

      {error && <div style={{ color: 'red', marginBottom: '1em' }}>{error}</div>}

      <div>
  {homes.length > 0 ? (
    homes.map((home) => {
      const hasLinkOrDocument = home.link || home.file_url; // Check if either link or document exists

      return (
        <div
          key={home.id}
          className={`address-card mb-2 ${hasLinkOrDocument ? 'clickable' : ''}`} // Add 'clickable' class only if link or document exists
          onClick={() => hasLinkOrDocument && handleCardClick(home)} // Only trigger click if link or document exists
        >
          <div className="card-address">
            {home.address}
            <span className="italic pl-xsm">
              By: {home.license_number} on {formatDate(home.created_at)} ({home.sale_type || 'Sale'})
            </span>
          </div>
          <div className="card-bac d-flex align-items-center justify-content-end">
            {!hasLinkOrDocument && ( // Show FaLinkSlash only if neither link nor document exists
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-${home.id}`}>No document or link provided</Tooltip>}
              >
                <span className="broken-link-icon">
                  <FaLinkSlash />
                </span>
              </OverlayTrigger>
            )}
            <span>{home.bac}</span>
          </div>
        </div>
              );
            })
          ) : (
            hasSearched ? (
              <div className="no-results marg-t-sm">No homes found</div>
            ) : (
              <div className="no-results marg-t-sm">Enter an address to search for a listing.</div>
            )
          )}
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Listing Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-centered">
            {/* If the document exists, display a link to open it in a new tab */}
            {selectedDocument ? (
              <>
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    href={selectedDocument}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="open-tab-link"
                  >
                    Open in a new tab
                    <span className='marg-l-sm'><ImNewTab/></span>
                  </a>
                </div>
                {/* Display the document in an iframe */}
                <iframe
                  src={selectedDocument}
                  title="Document"
                  style={{ width: '100%', height: '400px' }}
                  frameBorder="0"
                />
              </>
            ) : (
              <p>No document available.</p>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex flex-column align-items-stretch">
            {/* Check if there's a link */}
            {selectedLink ? (
              <>
                <div className="text-centered">
                  Are you sure you want to navigate to{' '}
                  <a href={selectedLink} target="_blank" rel="noopener noreferrer">
                    {selectedLink}
                  </a>?
                </div>
                <div className="no-results italic text-center pbot-xs" style={{ margin: '0 auto' }}>
                  Please note that we cannot verify the authenticity or safety of external links.
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <Button variant="secondary" className="cancel-button mx-2" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button variant="primary" className="mx-2 btn-primary2" onClick={handleConfirmNavigation}>
                    Navigate
                  </Button>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between mt-2">
                <p className="no-results italic text-center pbot-xs mx-2" style={{ margin: '0 auto' }}>
                  No link provided.
                </p>
                <Button variant="secondary" className="cancel-button mx-2" onClick={handleCloseModal}>
                  Close
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default SearchHomes;