import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../AuthContext';

const NavBar = () => {
  const { isLoggedIn, user, logout } = useAuth(); // Ensure 'user' is destructured from useAuth
  const [expanded, setExpanded] = useState(false); // State to track if navbar is expanded
  const navigate = useNavigate(); // Initialize navigate

  const handleToggle = () => setExpanded(!expanded); // Toggle navbar
  const handleClose = () => setExpanded(false); // Close navbar

  const handleManageListingsClick = () => {
    if (isLoggedIn) {
      navigate('/my-listings');
    } else {
      navigate('/login');
    }
    handleClose(); // Close the navbar after navigating
  };

  return (
    <Navbar expand="lg" expanded={expanded} id="navbar" className='navbar-dark bg-dark nav py-sm'>
      <Container className='nav'>
        <Navbar.Brand as={Link} to="/" onClick={handleClose}>Search Listings</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={handleManageListingsClick}>Manage Listings</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            {!isLoggedIn ? (
              <>
                <Nav.Link as={Link} to="/login" onClick={handleClose}>Login</Nav.Link>
                <Nav.Link as={Link} to="/signup" onClick={handleClose}>Sign Up</Nav.Link>
              </>
            ) : (
              <>
              </>
            )}
          </Nav>
          {isLoggedIn && user?.license && (
            <Nav className="ms-auto">
              <Nav.Link onClick={handleManageListingsClick}>License #{user.license}</Nav.Link>
              <Nav.Link onClick={() => { handleClose(); logout(); }}>Logout</Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;