import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/login.scss'; // Ensure this file includes the background styling
import { API_URL } from './Constants';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();  // Using useNavigate for navigation

  const handleReset = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) {
      newErrors.email = 'Email is required.';
      setErrors(newErrors);
      return;
    }

    setIsButtonDisabled(true);  // Disable the button immediately

    try {
      const response = await axios.post(
        `${API_URL}/password/reset`,  // Your custom password reset endpoint
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setMessage('If an account with that email exists, you will receive a password reset link.');
      setErrors({});

      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);

    } catch (error) {

      // Extract error message from the backend response and set it in state
      if (error.response && error.response.data && error.response.data.error) {
        setErrors({ server: error.response.data.error });
      } else {
        setErrors({ server: 'An unexpected error occurred. Please try again.' });
      }

      // Re-enable the button after 5 seconds even if there is an error
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    }
  };

  const handleBackToSignIn = () => {
    navigate('/login');  // Navigate back to the sign-in page
  };

  return (
    <div className="background">
      <div className="login-form">
        <Card style={{ width: '25rem' }}>
          <Card.Header className="text-center">Password Reset</Card.Header>
          <Card.Body>
            {message && <div className="success-message">{message}</div>}
            <Form onSubmit={handleReset}>
              {errors.server && (
                <div className="error-message">{errors.server}</div>
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex justify-content-between mt-3">
                <Button
                  variant="primary"
                  className="btn-primary2"
                  type="submit"
                  disabled={isButtonDisabled}  // Disable the button based on state
                >
                  Reset password
                </Button>
                <Button 
                  variant="secondary" 
                  className="btn-primary2" 
                  onClick={handleBackToSignIn}
                >
                  Back to sign in
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <div className="ptop-xs italic text-centered">
          Having trouble logging in? Please contact
          <a className="marg-l-xsm" href="mailto:help.whatisbac@gmail.com">
            help.whatisbac@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;