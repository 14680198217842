import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchHomes from './components/SearchHomes';
import MyListings from './components/MyListings';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Navbar from './components/NavBar';
import PasswordReset from './components/PasswordReset';
import ResetPasswordForm from './components/ResetPasswordForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/login.scss';
import { useSearchParams } from 'react-router-dom';
import Footer from './components/Footer';
import { LoadScript } from '@react-google-maps/api';
import { libraries, googleMapsApiKey } from './components/Constants';

function App() {
  // Extract the reset_password_token from the URL query parameters
  const ResetPasswordWithToken = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('reset_password_token');
    
    return <ResetPasswordForm token={token} />;
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
      <Router>
        <Navbar />
        <div className="container">
          <Routes>
            <Route path="/" element={<SearchHomes />} />
            <Route path="/homes" element={<SearchHomes />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/my-listings" element={<MyListings />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            {/* Pass the token prop to ResetPasswordForm via ResetPasswordWithToken component */}
            <Route path="/reset-password" element={<ResetPasswordWithToken />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </LoadScript>
  );
}

export default App;