import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <footer className="text-light footer navbar-dark bg-dark py-md text-center">
      <div>
        By using this site, you agree to our{' '}
        <Link onClick={handleShowModal} style={{ textDecoration: 'underline' }}>
          terms and conditions
        </Link>.
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Certification and Acknowledgment</h5>
            <p>I certify that I am a licensed real estate agent and that the real estate license number I provide is both valid and accurate. I confirm that I will only input properties for which I am the contracted listing agent, and that the Buyer Agent Compensation (BAC) I enter is true and accurately reflects my listing agreement. I acknowledge that providing false or misleading information may lead to disciplinary action, including potential removal from this platform. I agree to comply with all relevant local, state, and federal laws and regulations governing real estate transactions and marketing practices. I understand that it is my responsibility to ensure the accuracy and legality of all information I submit.</p>

            <h5>Modification of Terms</h5>
            <p>The platform reserves the right to update or modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the platform constitutes acceptance of the updated terms. By agreeing to these terms and conditions, I confirm that I have read, understood, and accepted all the provisions outlined herein.</p>

            <h5>Limitation of Liability</h5>
            <p>By accessing or using this website, you agree that the owners, operators, employees, and affiliates of this website (collectively referred to as "Whatisbac") shall not be held liable for any damages, losses, or claims arising out of or in connection with your use of the website or the information contained therein.</p>

            <h5>No Warranties</h5>
            <p>The content and services provided on this website are provided "as is" without any warranties of any kind, either express or implied. Whatisbac does not guarantee that the website will be error-free, secure, or uninterrupted.</p>

            <h5>Indemnification</h5>
            <p>You agree to indemnify, defend, and hold harmless Whatisbac, its affiliates, and agents from and against any and all claims, damages, losses, liabilities, and expenses, including legal fees, arising out of or related to your use of the website, your violation of these terms, or your infringement of any third-party rights.</p>

            <h5>Arbitration Agreement</h5>
            <p>Any dispute, controversy, or claim arising out of or relating to these terms, or the breach thereof, shall be settled by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in Travis County, Texas, and the decision rendered by the arbitrator(s) shall be final and binding on all parties. You waive any right to a trial by jury or to participate in a class action lawsuit.</p>

            <h5>Waiver of Right to Sue</h5>
            <p>To the fullest extent permitted by law, you waive any and all rights to bring any claims or actions in any court of law against Whatisbac, its directors, officers, employees, agents, or affiliates. Any legal action against Whatisbac must be brought within one (1) year after the cause of action arises.</p>

            <h5>Governing Law</h5>
            <p>These terms shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of laws principles.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </footer>
  );
};

export default Footer;