import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import { API_URL } from './Constants';
import '../styles/login.scss';

const EditHome = ({ home, onDelete, onEdit, externalErrors }) => {
  const [address, setAddress] = useState(home.address || '');
  const [bac, setBac] = useState(home.bac || '');
  const [link, setLink] = useState(home.link || '');
  const [saleType, setSaleType] = useState(home.sale_type || 'Sale');
  const [errors, setErrors] = useState(externalErrors || []);
  const [showModal, setShowModal] = useState(false);
  const [existingFileUrl, setExistingFileUrl] = useState(null); // Store the URL of the existing file
  const [newFile, setNewFile] = useState(null); // Track new file uploaded
  const [fileType, setFileType] = useState(null); // Track file type for rendering
  const [formModified, setFormModified] = useState(false); // Track if form has been modified
  const autocompleteRef = useRef(null);

  // Initial setup of home data and file info
  useEffect(() => {
    if (home) {
      setAddress(home.address);
      setBac(home.bac);
      setLink(home.link);
      setSaleType(home.sale_type || 'Sale');
      
      if (home.file_url) {
        // Only prepend API_URL if it's not already included
        const isFullUrl = home.file_url.startsWith('http');
        const fullFileUrl = isFullUrl ? home.file_url : `${API_URL}${home.file_url}`;
        
        setExistingFileUrl(fullFileUrl); // Set the full file URL
        determineFileType(home.file_url); // Determine the file type for display
      }
    }
  }, [home]);

  // Display any external errors
  useEffect(() => {
    if (externalErrors) {
      setErrors(externalErrors);
    }
  }, [externalErrors]);

  // Determine file type (image, PDF, or others)
  const determineFileType = (fileUrl) => {
    const extension = fileUrl.split('.').pop();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      setFileType('image');
    } else if (extension === 'pdf') {
      setFileType('pdf');
    } else {
      setFileType('other');
    }
  };

  const handleLoad = useCallback((autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const handlePlaceChanged = useCallback(() => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      setAddress(place.formatted_address);
      setFormModified(true); // Mark form as modified
    }
  }, []);

  const handleSaleTypeChange = useCallback((e) => {
    setSaleType(e.target.value);
    setFormModified(true); // Mark form as modified
  }, []);

  const handleInputChange = useCallback((setStateFn, value) => {
    setStateFn(value);
    setFormModified(true); // Mark form as modified
  }, []);

  // Form submission handler for editing the home
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      
      // If no new file is selected, send the existing file URL
      const fileToSubmit = newFile || (existingFileUrl ? { url: existingFileUrl } : null);
  
      const updatedHome = await onEdit(e, home, address, bac, link, saleType, fileToSubmit); // Wait for the updated home
  
      // Update the state with the new file URL if a file was uploaded
      if (updatedHome?.file_url) {
        setExistingFileUrl(`${API_URL}${updatedHome.file_url}`);
      }
    },
    [onEdit, home, address, bac, link, saleType, newFile, existingFileUrl] // Include dependencies
  );  

  // Handle home deletion
  const handleDelete = useCallback(() => {
    onDelete(home.id); // Use the passed onDelete callback
  }, [onDelete, home.id]);

  // Handle file selection
  const handleFileChange = useCallback((e) => {
    setNewFile(e.target.files[0]);
    setFormModified(true); // Mark form as modified
  }, []);

  // Handle file removal (sets the URL and newFile state to null)
  const handleFileRemove = useCallback(() => {
    setExistingFileUrl(null);
    setNewFile(null);
    setFormModified(true); // Mark form as modified
  }, []);

  return (
    <div className="add-home-form marg-t-md">
      <Card style={{ width: '100%' }}>
        <Card.Header className="text-center">Edit Listing</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            {/* Display form errors */}
            {errors.length > 0 && (
              <div className="error-message">
                {errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            )}

            {/* Address input */}
            <Form.Group controlId="formAddress">
              <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Search for an address"
                    value={address}
                    onChange={(e) => handleInputChange(setAddress, e.target.value)}
                    style={{ padding: '0.5rem' }}
                  />
                </div>
              </Autocomplete>
            </Form.Group>

            {/* BAC input */}
            <Form.Group controlId="formBac">
              <Form.Control
                type="text"
                value={bac}
                onChange={(e) => handleInputChange(setBac, e.target.value)}
                placeholder="Buyer agent compensation offered"
              />
            </Form.Group>

            {/* Link input */}
            <Form.Group controlId="formLink">
              <Form.Control
                type="text"
                value={link}
                onChange={(e) => handleInputChange(setLink, e.target.value)}
                placeholder="Enter optional link (e.g., compensation document)"
              />
            </Form.Group>

            {/* Sale Type Radio Buttons */}
            <Form.Group controlId="formSaleType">
              <div>
                <Form.Check
                  type="radio"
                  label="Sale"
                  name="saleType"
                  value="Sale"
                  checked={saleType === 'Sale'}
                  onChange={handleSaleTypeChange}
                  inline
                />
                <Form.Check
                  type="radio"
                  label="Lease"
                  name="saleType"
                  value="Lease"
                  checked={saleType === 'Lease'}
                  onChange={handleSaleTypeChange}
                  inline
                />
              </div>
            </Form.Group>

            {/* File Upload and Display */}
            <Form.Group controlId="formFile marg-b-md">
              {existingFileUrl ? (
                <div className="file-info">
                  {/* Display existing file based on file type */}
                  {fileType === 'image' ? (
                    <img src={existingFileUrl}
                    key={existingFileUrl}
                    alt="Uploaded" style={{ width: '100%' }} />
                  ) : fileType === 'pdf' ? (
                    <iframe
                      src={existingFileUrl}
                      key={existingFileUrl}
                      title="PDF Viewer"
                      style={{ width: '100%', height: '400px' }}
                    />
                  ) : (
                    <p>
                      Unsupported file type.{' '}
                      <a href={existingFileUrl} target="_blank" rel="noopener noreferrer">
                        Download
                      </a>
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <Form.Label>Upload a file (optional)</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </div>
              )}
            </Form.Group>

            <div className="d-flex justify-content-between align-items-end">
              <Button
                variant="primary"
                className={`btn-primary2 ${!formModified ? 'disabled-button' : ''}`} // Add 'disabled-btn' class if form is not modified
                type="submit"
                disabled={!formModified} // Disable the button if the form is not modified
              >
                Save changes
              </Button>
              <div className="d-flex flex-column align-items-end" style={{ marginLeft: 'auto' }}>
                {existingFileUrl ? (
                  <Button
                    variant="danger"
                    className="mb-2 marg-t-sm" // Add margin bottom to stack vertically
                    onClick={handleFileRemove}
                  >
                    Remove File
                  </Button>
                ) : null}
                <Button variant="danger" className="btn-primary2" onClick={handleDelete}>
                  Delete Home
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>

      {/* Error Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 &&
            errors.map((error, index) => <div key={index}>{error}</div>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditHome;