import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import '../styles/login.scss'; // Ensure this file includes the background styling
import { API_URL } from './Constants';

const ResetPasswordForm = ({ token }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!password) {
      newErrors.password = 'Password is required.';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long.';
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsButtonDisabled(true);

    try {
      const response = await axios.patch(
        `${API_URL}/users/password`,
        {
          user: {
            reset_password_token: token,
            password,
            password_confirmation: confirmPassword,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setMessage('Your password has been successfully reset. You will be rerouted to login shortly.');
      setErrors({});
      setTimeout(() => {
        setIsButtonDisabled(false);
        navigate('/login');
      }, 5000);

    } catch (error) {
      setErrors({ server: 'An unexpected error occurred. Please try again.' });
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="background">
      <div className="signup-form marg-t-md">
        <Card style={{ width: '25rem' }}>
          <Card.Header className="text-center">Reset Your Password</Card.Header>
          <Card.Body>
            {message && <div className="success-message">{message}</div>}
            <Form onSubmit={handleReset}>
              {errors.server && (
                <div className="error-message">{errors.server}</div>
              )}
              <Form.Group controlId="formBasicPassword">
                <Form.Label>New password</Form.Label>
                {errors.password && (
                  <div className="error-message">{errors.password}</div>
                )}
                <div className="password-input-container">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your new password"
                    isInvalid={!!errors.password}
                    style={{ flex: 1 }}
                  />
                  <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm new password</Form.Label>
                {errors.confirmPassword && (
                  <div className="error-message">{errors.confirmPassword}</div>
                )}
                <div className="password-input-container">
                  <Form.Control
                    type={confirmPasswordVisible ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your new password"
                    isInvalid={!!errors.confirmPassword}
                    style={{ flex: 1 }}
                  />
                  <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex justify-content-between mt-3">
                <Button
                  variant="primary"
                  className="btn-primary2"
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  Reset password
                </Button>
                <Button 
                  variant="secondary" 
                  className="btn-primary2" 
                  onClick={() => navigate('/login')}
                >
                  Back to sign in
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ResetPasswordForm;