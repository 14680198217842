import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import '../styles/login.scss'; // Ensure this file includes the background styling
import { useAuth } from '../AuthContext'; // Import useAuth hook
import { Link } from 'react-router-dom';

const LogIn = () => {
  const [licenseNumber, setLicenseNumber] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login function from useAuth

  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    if (!licenseNumber) {
      newErrors.licenseNumber = 'License number is required.';
    }
  
    if (!password) {
      newErrors.password = 'Password is required.';
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const success = await login(licenseNumber, password);
      if (success) {
        setErrors({}); // Clear errors on successful login
        navigate('/my-listings');
      } else {
        setErrors({ server: 'Login failed. Please check your credentials.' });
      }
    } catch (error) {
      setErrors({ server: 'An unexpected error occurred. Please try again.' });
    }
  }, [licenseNumber, password, login, navigate]);

  const handleSignUp = () => {
    navigate('/signup'); // Redirect to sign-up page
  };

  return (
    <div className="background">
      <div className="login-form">
        <Card style={{ width: '25rem' }}>
          <Card.Header className="text-center">Sign In</Card.Header>
          <Card.Body>
            <Form onSubmit={handleLogin}>
              {errors.server && (
                <div className="error-message">{errors.server}</div>
              )}
              <Form.Group controlId="formBasicLicenseNumber">
                <Form.Label>License number</Form.Label>
                <Form.Control
                  type="text"
                  value={licenseNumber}
                  placeholder="License number"
                  onChange={(e) => setLicenseNumber(e.target.value)}
                  isInvalid={!!errors.licenseNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.licenseNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" className="btn-primary2" type="submit">
                Sign in
              </Button>
              <Button
                className="btn-primary2"
                variant="secondary"
                style={{ float: 'right' }}
                onClick={handleSignUp}
              >
                Sign up
              </Button>
            </Form>
            <div className="mt-3">
              <Link to="/password-reset">Forgot your password?</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default LogIn;