import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { API_URL } from './Constants';

const Signup = () => {
  const [license, setLicense] = useState('');
  const [confirmLicense, setConfirmLicense] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleShowModal = (e) => {
    e.preventDefault(); // Prevent form submission when the button is clicked
    // Don't show modal if there are errors in form
    checkErrors(e, 'Modal');
  };

  const handleAcceptTerms = (e) => {
    e.preventDefault(); // Prevent form submission when the button is clicked
    // Don't show modal if there are errors in form
    checkErrors(e, 'Terms');
  };

  const handleCloseModal = () => setShowModal(false);

  const checkErrors = (e, action) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) {
      newErrors.email = 'Email is required.';
    }

    if (!license) {
      newErrors.license = 'License number is required.';
    } else if (license !== confirmLicense) {
      newErrors.confirmLicense = 'License numbers do not match.';
    }

    if (!password) {
      newErrors.password = 'Password is required.';
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');
    }

    if (action === 'Modal') {
      setShowModal(true);
    } else {
      submitForm(e);
    }
  }

  const submitForm = useCallback(async (e) => {
    e.preventDefault();
    setTermsAccepted(true);
    setShowModal(false);
  
    try {
      const response = await axios.post(
        `${API_URL}/register`,
        {
          user: {
            license,
            email,
            password,
            agreed_to_terms: true,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 201) {
        navigate('/login'); // Redirect to login page after successful signup
      } else {
        setErrors({ server: 'Signup failed. Please try again.' });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
      setErrors((prevErrors) => (prevErrors.server !== errorMessage ? { server: errorMessage } : prevErrors));
    }
  }, [license, email, password, navigate]);

  return (
    <div className="background">
      <div className="signup-form marg-t-md">
        <Card style={{ width: '25rem' }}>
          <Card.Header className="text-center">Sign Up</Card.Header>
          <Card.Body>
            <Form onSubmit={handleShowModal}>
              {errors.server && (
                <div className="error-message">{errors.server}</div>
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                {errors.email && (
                  <div className="error-message">{errors.email}</div>
                )}
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email address"
                  isInvalid={!!errors.email}
                />
              </Form.Group>
              <Form.Group controlId="formBasicLicense">
                <Form.Label>Real estate license</Form.Label>
                {errors.license && (
                  <div className="error-message">{errors.license}</div>
                )}
                <Form.Control
                  type="text"
                  value={license}
                  onChange={(e) => setLicense(e.target.value)}
                  placeholder="Enter license number"
                  isInvalid={!!errors.license}
                />
              </Form.Group>
              <Form.Group controlId="formConfirmLicense">
                <Form.Label>Confirm real estate license</Form.Label>
                {errors.confirmLicense && (
                  <div className="error-message">{errors.confirmLicense}</div>
                )}
                <Form.Control
                  type="text"
                  value={confirmLicense}
                  onChange={(e) => setConfirmLicense(e.target.value)}
                  placeholder="Confirm license number"
                  isInvalid={!!errors.confirmLicense}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                {errors.password && (
                  <div className="error-message">{errors.password}</div>
                )}
                <div className="password-input-container">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    isInvalid={!!errors.password}
                    style={{ flex: 1 }}
                  />
                  <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm password</Form.Label>
                {errors.confirmPassword && (
                  <div className="error-message">{errors.confirmPassword}</div>
                )}
                <div className="password-input-container">
                  <Form.Control
                    type={confirmPasswordVisible ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm password"
                    isInvalid={!!errors.confirmPassword}
                    style={{ flex: 1 }}
                  />
                  <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </Form.Group>
              <Button 
                variant="primary" 
                type="submit" 
                className='btn-primary2' 
              >
                Sign up
              </Button>
              <Button
                variant="secondary"
                onClick={() => navigate('/login')}
                style={{ float: 'right' }}
                className='btn-primary2'
              >
                Have an account? Log in
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className='ptop-xs italic text-centered'>
          Having trouble signing up? Please contact 
          <a className='marg-l-xsm' href="mailto:help.whatisbac@gmail.com">
            help.whatisbac@gmail.com
          </a>
        </div>
      </div>

      {/* Modal for Terms and Conditions */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Certification and Acknowledgment</h5>
            <p>I certify that I am a licensed real estate agent and that the real estate license number I provide is both valid and accurate. I confirm that I will only input properties for which I am the contracted listing agent, and that the Buyer Agent Compensation (BAC) I enter is true and accurately reflects my listing agreement. I acknowledge that providing false or misleading information may lead to disciplinary action, including potential removal from this platform. I agree to comply with all relevant local, state, and federal laws and regulations governing real estate transactions and marketing practices. I understand that it is my responsibility to ensure the accuracy and legality of all information I submit.</p>

            <h5>Modification of Terms</h5>
            <p>The platform reserves the right to update or modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the platform constitutes acceptance of the updated terms. By agreeing to these terms and conditions, I confirm that I have read, understood, and accepted all the provisions outlined herein.</p>

            <h5>Limitation of Liability</h5>
            <p>By accessing or using this website, you agree that the owners, operators, employees, and affiliates of this website (collectively referred to as "Whatisbac") shall not be held liable for any damages, losses, or claims arising out of or in connection with your use of the website or the information contained therein.</p>

            <h5>No Warranties</h5>
            <p>The content and services provided on this website are provided "as is" without any warranties of any kind, either express or implied. Whatisbac does not guarantee that the website will be error-free, secure, or uninterrupted.</p>

            <h5>Indemnification</h5>
            <p>You agree to indemnify, defend, and hold harmless Whatisbac, its affiliates, and agents from and against any and all claims, damages, losses, liabilities, and expenses, including legal fees, arising out of or related to your use of the website, your violation of these terms, or your infringement of any third-party rights.</p>

            <h5>Arbitration Agreement</h5>
            <p>Any dispute, controversy, or claim arising out of or relating to these terms, or the breach thereof, shall be settled by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in Travis County, Texas, and the decision rendered by the arbitrator(s) shall be final and binding on all parties. You waive any right to a trial by jury or to participate in a class action lawsuit.</p>

            <h5>Waiver of Right to Sue</h5>
            <p>To the fullest extent permitted by law, you waive any and all rights to bring any claims or actions in any court of law against Whatisbac, its directors, officers, employees, agents, or affiliates. Any legal action against Whatisbac must be brought within one (1) year after the cause of action arises.</p>

            <h5>Governing Law</h5>
            <p>These terms shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of laws principles.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAcceptTerms}>
            Accept terms
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Signup;